<template>
  <div>
    <div class="mb-10">
      <div class="d-flex pa-4">
        <div>
          <h3>
            Ответственный: {{ data.manager }}<br />Текущий статус:
            <span
              ><a-view-base-select
                v-if="$root.config.meta.models.caller_item.data.status"
                :value="data.status"
                :model="$root.config.meta.models.caller_item.data.status"
            /></span>
          </h3>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn v-if="data.status == 1" class="ma-1" @click="onClickEvent(3)"
            >Недозвон</v-btn
          >
          <v-btn class="ma-1" @click="onClickEvent(5)">Перезвонить</v-btn>
          <v-btn class="ma-1" @click="onClickEvent(2)">Согласен</v-btn>
          <v-btn class="ma-1" @click="onClickEvent(4)">Отказ</v-btn>
          <v-btn class="ma-1" @click="onSubmit()">Записать</v-btn>
          <v-btn class="ma-1" @click="sendSMS(2)">Скидка в бот</v-btn>
          <v-btn class="ma-1" @click="sendSMS(3)">Доступ24</v-btn>
          <v-btn class="ma-1" @click="sendSMS(1)">Послать СМС</v-btn>
          <v-btn class="ma-1" @click="showDialogTele = true"
            >Whatsapp</v-btn
          >
        </div>
      </div>
      <DialogWhats
        v-model="showDialogTele"
        :phone="data.phone"
        :name="data.name"
        :item_id="data.id"
      />
      <DialogStatus
        v-model="showDialogStatus"
        :status="data.status"
        :item_id="data.id"
      />
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="6">
          <base-material-card>
            <template v-slot:heading>
              <div>Скрипт разговора</div>
            </template>
            <div
              style="white-space: pre-line; overflow: auto"
              v-html="$root.config.meta.const.scriptText"
            />
          </base-material-card>
        </v-col>
        <v-col cols="12" md="6">
          <base-material-card>
            <template v-slot:heading>
              <div>Информация</div>
            </template>
            <div>
              <a-loader v-if="loading" />
              <div>
                Кампания: <b>{{ data.campaign }}</b>
              </div>
              <div>
                Телефон: <b>{{ data.phonedata }}</b>
              </div>
              <div v-for="(key, i) of info" :key="i">
                {{ i }}: <b>{{ key }}</b>
              </div>
            </div>
            <v-text-field outlined v-model="data.name" label="Имя" />
            <v-radio-group v-model="data.item_type" label="Статус клиента">
              <v-radio
                v-for="(el, key) in item_types"
                :key="key"
                :label="el.text"
                :value="el.value"
              ></v-radio>
            </v-radio-group>
            <v-textarea
              outlined
              v-model="data.comment"
              label="Комментарий"
            ></v-textarea>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-6">
          <base-material-card color="warning">
            <template v-slot:heading>
              <v-tabs
                v-model="tab"
                background-color="transparent"
                slider-color="white"
              >
                <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{
                  tab.title
                }}</v-tab>
              </v-tabs>
            </template>
            <v-tabs-items
              v-model="tab"
              class="transparent"
              style="min-height: 200px"
            >
              <v-tab-item>
                <v-card-text>
                  <CardCalls :phone="data.phonedata" />
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <CardTasks :id="data.id" />
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text>
                  <CardStatuses :id="data.id" />
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </base-material-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  mixins: [],
  components: {
    CardCalls: () => import("./cardCalls"),
    CardTasks: () => import("./cardTasks"),
    CardStatuses: () => import("./cardStatuses"),
    DialogStatus: () => import("./dialogStatus"),
    DialogWhats: () => import("./dialogWhats.vue"),
  },
  data() {
    return {
      loading: false,
      showDialogStatus: false,
      showDialogTele: false,
      tab: 0,
      tabs: [{ title: "Звонки" }, { title: "Задания" }, { title: "Статусы" }],
      info: {},
      item_types: [
        {
          value: 1,
          text: "частник",
        },
        {
          value: 2,
          text: "подборщик",
        },
        {
          value: 3,
          text: "перекуп",
        },
      ],
      data: {
        name: null,
        item_type: 1,
        phone: null,
        comment: null,
        status: null,
      },
      errors: {},
      globModel: {},
    };
  },
  created() {
    this.onGetNewItem();
  },
  watch: {
    id() {
      this.onGetNewItem();
    },
  },
  methods: {
    async onGetNewItem() {
      this.$root.title = "Задание на звонок";
      if (this.id) await this.getItem(this.id);
    },
    async onSubmit() {
      this.loading = true;
      let response = await this.$axios.p(
        this.$root.api.cardItem + "/caller_item/",
        this.data
      );
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
      }
    },
    async onClickEvent(status) {
      this.data.status = status;
      await this.onSubmit();
      this.showDialogStatus = true;
    },
    async sendSMS(v) {
      if (v == 1) {
        try {
          await this.$axios.g(
            this.$root.api.sendsms + "/" + this.data.phonedata + "/" + v
          );
          this.$root.$emit("show-info", {
            text: "Послали сообщение на номер " + this.data.phonedata,
          });
        } catch (error) {
          this.$root.$emit("show-info", {
            text: "ОШИБКА, сообщение не послано",
          });
        }
      } else if (v == 2 || v == 3) {
        if (this.info.clientid) {
          try {
            await this.$axios.g(
              this.$root.api.sendbot + "/" + v + "/" + this.info.clientid
            );
            this.$root.$emit("show-info", {
              text: "Послали сообщение на номер " + this.info.clientid,
            });
          } catch (error) {
            this.$root.$emit("show-info", {
              text: "ОШИБКА, сообщение не послано",
            });
          }
        } else {
          this.$root.$emit("show-info", {
            text: "ОШИБКА, сообщение не послано",
          });
        }
      }
    },
    async getItem(id) {
      this.loading = true;
      try {
        let response = await this.$axios.g(
          this.$root.api.cardItem + "/caller_item/" + id
        );
        let status = response.data.status == "ok";
        if (status) {
          response.data.data.phonedata = response.data.data.phone;
          Object.assign(this.data, response.data.data);
          this.$root.$emit("global-set-call", response.data.data.phonedata);
          try {
            this.info = JSON.parse(this.data.info);
          } catch (error) {}
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          text: "Не найдено",
        });
      }
      this.loading = false;
    },
  },
};
</script>
